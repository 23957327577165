import SiteWrapper from "../components/wrapper.tsx";
import { Helmet } from "react-helmet";
import ogImage from "../assets/images/pagerduty_dxable_og.png";

import "./404@gen.scss";

const Generated404 = () => {
  return (
    <SiteWrapper isLandingPage={false}>
      <Helmet>
        <title>
          ページが見つかりません。 - PagerDuty正規代理店 - 株式会社Digital
          Stacks
        </title>

        <script
          type="text/javascript"
          defer
          src="https://app.secureprivacy.ai/script/639bffa95b4c7ff65172b36d.js"
        ></script>

        <meta property="twitter:card" content="summary_large_image" />

        <meta
          property="og:title"
          content="ページが見つかりません。 - PagerDuty正規代理店 - 株式会社Digital Stacks"
        />

        <meta property="og:url" content="https://pagerduty.dxable.com/404/" />
        <meta
          property="twitter:url"
          content="https://pagerduty.dxable.com/404/"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
        <meta
          property="twitter:image"
          content={"https://pagerduty.dxable.com" + ogImage}
        />
      </Helmet>

      <div className="wrapper404">
        <div className="center-2">
          <h2 className="center-title">ページが見つかりません</h2>
          <p className="center-para">
            指定されたURLのページは存在しません。 <br />
            サイト更新などによってURLが変更になったか、URLが正しく入力されていない可能性があります。
          </p>
          <button className="cta-btn-green">
            <a href="/">ホームページに戻る</a>
          </button>
        </div>
      </div>
    </SiteWrapper>
  );
};
export default Generated404;
